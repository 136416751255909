import { UserIcon, TicketIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import t, { trans } from '../../../translations/translate'
import { useState, useRef, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../../contexts/AuthContext'
import MenuIcon from '@mui/icons-material/Menu'
import { menuLinks } from './MainMenu'

export const MenuMobile = () => {
  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useRef<HTMLUListElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { isLoggedIn, authToken, currentUserData, logout } = useContext(AuthContext)

  const handleClose = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  return (
    <div className="flex flex-col items-center justify-center">
      <button
        onClick={() => {
          setShowMenu(!showMenu)
        }}
        ref={buttonRef}
        aria-controls={showMenu ? 'menu-mobile' : undefined}
        aria-haspopup="true"
        aria-label={trans('aria_label.menu_button')}>
        <MenuIcon
          sx={{
            color: 'var(--var-primary-orange)',
            width: '2rem',
            height: '2rem'
          }}
          aria-label={trans('aria_label.menu_icon')}
        />
      </button>
      {showMenu && (
        <ul
          className="absolute z-10 w-40 rounded-lg bg-white py-2 shadow-lg"
          style={{
            top: 'calc(100% + 10px)',
            left: 'calc(0% - 112px)'
          }}
          ref={menuRef}>
          {Object.keys(menuLinks).map((key) => {
            const link = menuLinks[key]
            return (
              <li className="p-2 text-base hover:text-primary-orange md:text-lg" key={key}>
                <Link to={link.path} onClick={handleClose} aria-label={link.ariaLabel} id={link.id}>
                  {link.label}
                </Link>
              </li>
            )
          })}
          {isLoggedIn && authToken && (
            <>
              <li className="mx-4 border-t border-gray-300"></li>
              <li className="p-2 text-base hover:text-primary-orange md:text-lg">
                <Link
                  to="/agencies/my-bookings"
                  onClick={handleClose}
                  className="flex flex-row items-center justify-center"
                  aria-label={trans('aria_label.my_bookings_link')}>
                  <UserIcon
                    className="mr-2 h-5 w-[20%] text-primary-orange"
                    aria-label={trans('aria_label.user_icon')}
                  />
                  <span className="w-[90%] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
                    {currentUserData?.Name}
                  </span>
                </Link>
              </li>
              <li className="p-2 text-base hover:text-primary-orange md:text-lg">
                <Link
                  to="/agencies/my-bookings"
                  onClick={handleClose}
                  className="flex flex-row items-center justify-center">
                  <TicketIcon className="mr-2 h-5 w-[20%] text-primary-orange opacity-0" aria-label="menu mobile" />
                  <span className="w-[90%] text-sm">{t('my_bookings.title')}</span>
                </Link>
              </li>
              <li className="mx-4 border-t border-gray-300"></li>
              <li className="p-2 text-base hover:text-primary-orange md:text-lg">
                <button
                  onClick={() => {
                    logout()
                    handleClose()
                  }}
                  className="flex w-full text-left text-base hover:text-primary-orange md:text-lg"
                  aria-label={trans('aria_label.logout_button')}>
                  <ArrowLeftOnRectangleIcon
                    className="mr-2 h-5 w-[20%] text-primary-orange"
                    aria-label={trans('aria_label.logout_icon')}
                  />
                  <span className="w-[90%] text-sm">{t('auth.logout')}</span>
                </button>
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  )
}
