import { useState, lazy, Suspense } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import { setLocation } from '../../redux/slices/searchFilterSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CardActions, TextField, InputAdornment } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { GOOGLE_API_KEY } from '../../utils/constants'
import { getAddressFromLocation, getCurrentPosition } from '../../utils/maps'
import { MapPinIcon, ChevronDownIcon, ChevronUpIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline'
import { RootState } from '../../redux/store'
import t, { trans } from '../../translations/translate'

const Filters = lazy(() => import('./Filters'))

function PlacesInput() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const searchFilters = useSelector((state: RootState) => state.searchFilter)
  const [showFilters, toggleFilters] = useState(false)
  const [inputAddress, setInputAddress] = useState(searchFilters.address || '')

  const [submitError, setSubmitError] = useState(false)
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')

  const search = (e: any) => {
    e.preventDefault()
    const { address, latitude, longitude } = searchFilters
    if (address === '') {
      setSubmitError(true)
      setSubmitErrorMessage(trans('filters.please_select_a_location'))
      return
    }

    if (latitude === 0 || longitude === 0) {
      setSubmitError(true)
      setSubmitErrorMessage(trans('filters.please_select_a_valid_location'))
      return
    }

    navigate('/booking/select-transfer')
  }

  const handleGetCurrentPosition = () => {
    getCurrentPosition(async (position: any) => {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      const address = await getAddressFromLocation({ lat: latitude, lng: longitude })
      dispatch(
        setLocation({
          latitude: latitude,
          longitude: longitude,
          address: address
        })
      )
      setInputAddress(address)
    })
  }

  const handleAddressChange = (address: string) => {
    const latitude = 0
    const longitude = 0

    dispatch(
      setLocation({
        ...searchFilters,
        latitude,
        longitude,
        address
      })
    )
    setInputAddress(address)
  }

  const { ref: materialRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      const address = place.formatted_address || ''
      const longitude = place.geometry?.location?.lng() || 0
      const latitude = place.geometry?.location?.lat() || 0

      if (!place) return
      dispatch(setLocation({ latitude, longitude, address }))
      setInputAddress(address)
    },
    inputAutocompleteValue: 'country',
    options: {
      componentRestrictions: { country: 'es' },
      types: ['geocode', 'establishment'], // https://developers.google.com/maps/documentation/javascript/place-autocomplete#constrain-place-types
      // Bounds limits the search only on the Canary Islands:
      bounds: {
        north: 29.5,
        south: 27.1,
        east: -13.05,
        west: -18.6
      },
      strictBounds: true
    }
  })

  const filterButtonClick = () => {
    toggleFilters(!showFilters)
    setTimeout(() => {
      document.getElementById('search')?.focus()
    }, 100)
  }

  return (
    <section className="container mx-auto flex h-full w-full flex-col items-center justify-center">
      <form className="content m-[1rem] flex w-full flex-wrap gap-4 md:w-[90%]">
        <TextField
          id="places-input"
          fullWidth
          variant="outlined"
          inputRef={materialRef}
          value={inputAddress}
          onChange={(e) => handleAddressChange(e.target.value)}
          onClick={() => {
            const input = document.querySelector('input')
            input?.select()
          }}
          className="w-[70%] rounded-[15px] border border-gray-200 p-2 shadow-md"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          error={submitError}
          helperText={submitError ? submitErrorMessage : ''}
          label={t('location')}
          sx={{
            '.MuiOutlinedInput-root > fieldset': {
              borderRadius: '15px 15px 15px 15px'
            }
          }}
          placeholder={trans('enter_your_location')}
          role="textbox"
        />

        <div className="flex w-full flex-col items-start justify-end gap-4 md:flex-row-reverse">
          <button
            id="current-location"
            className="tex-start flex w-full items-center rounded-[12px] border border-gray-200 bg-[#eeeeee] p-2 shadow-md hover:bg-gray-100 md:w-auto"
            onClick={(e) => {
              e.preventDefault()
              handleGetCurrentPosition()
            }}>
            <span className="mr-4 flex h-7 w-7 items-center justify-center rounded-full">
              <MapPinIcon className="h-6 w-6 text-primary-orange" />
            </span>
            <span>{t('use_my_current_location')}</span>
            <span className="ml-1" />
          </button>

          <button
            id="filters-button"
            className="tex-start flex w-full items-center rounded-[12px] border border-gray-200 bg-[#eeeeee] p-2 shadow-md hover:bg-gray-100 md:w-auto"
            onClick={(e) => {
              e.preventDefault()
              filterButtonClick()
            }}>
            <span className="mr-4 flex h-7 w-7 items-center justify-center rounded-full">
              <AdjustmentsVerticalIcon className="h-6 w-6 text-primary-orange" />
            </span>
            <span>{t('filters.title')}</span>
            <span className="ml-1">
              {showFilters ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
            </span>
          </button>
        </div>

        {showFilters && (
          <Suspense>
            <Filters />
          </Suspense>
        )}

        <CardActions sx={{ padding: '8px 0px' }} className="w-full">
          <button className="card-button shadow-lg" onClick={search} id="search-button">
            {t('search')}
          </button>
        </CardActions>
      </form>
    </section>
  )
}

export default PlacesInput
