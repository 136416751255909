export const es = {
  book_now: 'Reserva tu traslado al Aeropuerto',
  buy: 'Comprar',
  distance_km: 'Distancia (km)',
  island: 'Isla',
  loading_transfers: 'Cargando traslados...',
  no_transfers_found: 'No se han encontrado traslados en tu ubicación',
  price_per_pax: '{{price}} /pax',
  search: 'Buscar',
  select_service: 'Seleccionar',
  transfers: '{{count}} Traslado cerca de ti',
  transfers_other: '{{count}} Traslados cerca de ti',
  where_are_you: '¿Dónde estás?',
  contact_us: 'Contacta con nosotros',
  contact: 'Contacto',
  try_again: 'Volver a intentar',
  close: 'Cerrar',
  service_selector: 'Selecciona el servicio',
  use_my_current_location: 'Usar mi ubicación actual',
  location: 'Ubicación',
  enter_your_location: 'Introduce tu ubicación',
  home: {
    title: 'Traslados al aeropuerto en Canarias con <span className="font-bold text-primary-orange">Last</span><span className="font-bold text-primary-gray">Minute</span>',
    subtitle: 'Disponible en Tenerife, Gran Canaria, Lanzarote, Fuerteventura y La Palma. Reserva tu traslado al aeropuerto con antelación y ahorra tiempo y dinero. Disfruta de tu viaje sin preocupaciones.',
    book_now: '¡Reserva ahora!',
    lm_description: {
      title: '¿Cómo funciona LastMinute?',
      title_1: 'Reserva fácilmente tu plaza en la guagua',
      description_1: 'Con LastMinute, puedes reservar tu plaza en una guagua que pase por tu zona en las próximas 72 horas. ¡Así de fácil! No más complicaciones para llegar a tu aeropuerto de destino.',
      title_2: 'Encuentra la parada más cercana a ti',
      description_2: 'Basta con buscar por la zona, la hora y la distancia para ver las paradas por las que va a pasar una guagua. ¡Ya no tienes que preocuparte por cómo llegar al aeropuerto!',
      title_3: 'Rellena tus datos y haz el pago en línea',
      description_3: 'Rellenar el formulario con tus datos es sencillo y rápido. Además, puedes realizar el pago en línea de manera segura. Te llegará un email de confirmación a tu correo electrónico para que puedas estar seguro de que tu reserva se ha realizado con éxito.',
      title_4: 'Comodidad y tranquilidad en tu viaje',
      description_4: 'Con LastMinute, puedes disfrutar de un viaje cómodo y tranquilo hasta el aeropuerto de la isla. ¡Deja que nosotros nos encarguemos del transporte!',
      title_5: 'Únete a la comunidad de viajeros de CanaryShuttle',
      description_5: 'Únete a la comunidad de viajeros que confían en CanaryShuttle para llegar a su destino de manera cómoda y segura. ¡Descubre por qué somos la mejor opción para tu transporte!'
    }
  },
  buttons: {
    home: 'Inicio'
  },
  filters: {
    title: 'Filtros',
    transfer_date: 'Fecha traslado',
    transfer_time: 'Hora traslado',
    radius: 'Radio de búsqueda (km)',
    address: 'Dirección',
    radius_info: 'Buscaremos los traslados más cercanos a tu ubicación, pero puedes establecer un radio de búsqueda diferente.',
    please_select_a_location: 'Por favor, selecciona una ubicación',
    please_select_a_valid_location: 'Por favor, selecciona una ubicación válida'
  },
  booking_form: {
    title: 'Formulario de reserva',
    error_saving_booking: 'Ha ocurrido un error al guardar la reserva',
    contact: {
      title: 'Detalles de contacto',
      name: 'Nombre',
      email: 'Email',
      email_confirmation: 'Confirmar email',
      phone: 'Teléfono',
      contact_details_info: 'Por favor, rellena los siguientes campos con tus datos de contacto.',
      user_emails: 'Correo electrónico del usuario',
      other_email: 'Otro:'
    },
    validations: {
      email_error: 'Debe escribir un email válido',
      name_error: 'Debe escribir un nombre válido',
      email_confirmation_error: 'El email de confirmación no coincide',
      phone_error: 'Debe escribir un teléfono válido'
    },
    pax: {
      title: '¿Quién te acompaña?',
      adults: 'Adultos',
      adults_info: 'Más de 12 años',
      babies: 'Bebés',
      babies_info: 'De 0 a 3 años',
      children: 'Niños',
      children_info: 'De 3 a 12 años'
    },
    extra: {
      title: 'Equipaje extra',
      surf_board: 'Tabla de surf',
      bicycles: 'Bicicleta',
      baby_seat: 'Asientos de bebe',
      golf: 'Golf',
      special: 'Especial',
      no_extra_luggage_for_this_service: 'No hay opciones de equipaje extra disponibles para este servicio.'
    },
    transfer_info: {
      title: 'Información del traslado',
      date: '<strong>Fecha:</strong> <span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{date}}</span>',
      end_place: '<strong>Destino:</strong> <span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{end_place}}</span>',
      end_time: '<span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{end_time}}</span>',
      island: '<strong>Isla:</strong> <span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{island}}</span>',
      start_place: '<strong>Lugar recogida:</strong> <span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{start_place}}</span>',
      start_time: '<span className="border-1 shadow-md hover:shadow-lg text-sm py-1 px-2 rounded-xl">{{start_time}}</span>'
    },
    agency: {
      title: 'Agencia',
      external_booking_id: 'ID reserva externo',
      external_booking_id_info: 'Puede introducir un ID de reserva externo que identifique esta reserva en su sistema.'
    }
  },
  payment_error: {
    title: 'No se ha podido procesar el pago',
    message: 'Lo sentimos, pero parece que ha habido un problema al procesar su pago. Por favor, inténtelo de nuevo o seleccione otro método de pago. Si el problema persiste, no dude en ponerse en contacto con nuestro servicio de atención al cliente para obtener ayuda.'
  },
  payment_success: {
    title: 'Mi reserva',
    sub_title: '¡Traslado confirmado!',
    welcome: 'Hola <strong>{{name}}</strong><br />Gracias por elegir Canary Shuttle.<br />Por favor, vea los detalles de su traslado más abajo.',
    start_time: '<span className="font-bold">Hora recogida</span>: <span className="text-primary-orange">{{start_time}}</span>',
    start_date: '<span className="font-bold">Fecha recogida</span>: <span className="text-primary-orange">{{start_date}}</span>',
    pickup_place: '<span className="font-bold">Punto recogida</span>',
    place: '<span className="font-bold">Lugar</span>: <span className="text-primary-orange">{{place}}</span>',
    coordinates: '<span className="font-bold">Coordenadas</span>:',
    passengers: '<span className="font-bold">Pasajeros</span>',
    adults: '<span className="font-bold">Adultos</span>: <span className="text-primary-orange">{{adults}}</span>',
    children: '<span className="font-bold">Niños</span>: <span className="text-primary-orange">{{children}}</span>',
    infants: '<span className="font-bold">Bebés</span>: <span className="text-primary-orange">{{infants}}</span>',
    destination: '<span className="font-bold">Punto de destino</span>',
    booking_not_found: 'Oops! No se ha encontrado la reserva',
    special_baggage: '<span className="font-bold">Equipaje especial</span>',
    baby_seats: '<span className="font-bold">Asientos de bebe</span>: <span className="text-primary-orange">{{baby_seats}}</span>',
    surf_table: '<span className="font-bold">Tabla de surf</span>: <span className="text-primary-orange">{{surf_table}}</span>',
    golf_bag: '<span className="font-bold">Bolsa de golf</span>: <span className="text-primary-orange">{{golf_bag}}</span>',
    bike: '<span className="font-bold">Bicicleta</span>: <span className="text-primary-orange">{{bike}}</span>',
    special: '<span className="font-bold">Especial</span>: <span className="text-primary-orange">{{special}}</span>',
    booking_id: '<span className="font-bold">ID Reserva</span>: <span className="text-primary-orange"># {{booking_id}}</span>',
    amount: '<span className="font-bold">Importe</span>: <span className="text-primary-orange">€ {{amount}}</span>',
    loading: 'Cargando...'
  },
  search_booking: {
    title: 'Buscar reserva',
    booking_id: 'Número de reserva',
    booking_id_placeholder: '104723',
    booking_id_helper_text: 'Puede encontrar el número de reserva en su email de confirmación',
    booking_date: 'Fecha de recogida',
    booking_date_error: 'Debe seleccionar una fecha válida'
  },
  index: {
    about_us: 'Sobre nosotros',
    about_us_description: 'LastMinute</span> es una aplicación de la empresa <span className="font-bold">CanaryShuttle</span>, especializada en <span className="font-bold">traslados en guagua</span> en las islas Canarias. Con <span className="font-bold">LastMinute</span>, puedes hacer <span className="font-bold">reservas de traslados</span> con solo 72 horas de antelación, ofreciéndote una experiencia de reserva <span className="font-bold">fácil y conveniente.'
  },
  service_card: {
    meeting_point: '<span className="font-bold min-w-fit">Punto de encuentro</span>:<span className="min-w-fit text-slate-800 ml-1 text-ellipsis">{{meeting_point}}</span>',
    available_pax: '<span className="font-bold min-w-fit">Plazas disponibles</span>:<span className="min-w-fit text-slate-800 ml-1">{{count}}</span>',
    start_time: '<span className="font-bold min-w-fit">Hora de salida</span>:<span className="min-w-fit text-slate-800 ml-1">{{start_time}}</span>',
    end_time: '<span className="font-bold min-w-fit">Hora de llegada</span>:<span className="min-w-fit text-slate-800 ml-1">{{end_time}}</span>',
    date: '<span className="font-bold min-w-fit">Fecha</span>:<span className="min-w-fit text-slate-800 ml-1">{{date}}</span>',
    distance: 'A {{distance}} km de tu ubicación',
    price_per_pax: '<span className="font-bold min-w-fit">Precio</span>:<span className="min-w-fit text-slate-800 ml-1">{{price_per_pax}}/pax</span>'
  },
  auth: {
    title: 'Iniciar sesión en <span className="font-bold text-primary-orange">Last</span><span className="font-bold text-primary-gray">Minute</span>',
    login_failed: 'Error al iniciar sesión',
    login_error: 'No se ha podido iniciar sesión. Usuario o passcode incorrectos',
    passcode_placeholder: 'Introduzca su passcode',
    username_placeholder: 'Introduzca su nombre de usuario',
    username: 'Nombre de usuario',
    passcode: 'Passcode',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión'
  },
  my_bookings: {
    title: 'Mis reservas',
    no_bookings: 'No tienes reservas',
    no_more_bookings: 'No hay más reservas',
    filters: {
      title: 'Filtros',
      booking_id: 'ID Reserva',
      start_date: 'Fecha de traslado (desde)',
      end_date: 'Fecha de traslado (hasta)',
      results_per_page: 'Nº de resultados',
      filter: 'Filtrar',
      clear: 'Limpiar'
    },
    table: {
      booking_date: 'Fecha de traslado',
      booking_id: 'ID',
      booking_paxnames: 'Pasajeros',
      booking_island: 'Isla',
      booking_start_place: 'Recogida',
      booking_start_time: 'Salida',
      booking_end_time: 'Llegada',
      booking_end_place: 'Destino',
      booking_price: 'Precio',
      booking_pax_names: 'Pasajeros',
      booking_pax_children: 'Niños',
      booking_pax_adults: 'Adultos',
      booking_pax_infants: 'Bebés',
      booking_pax_total: 'Total pasajeros',
      booking_special: 'Equipaje especial',
      booking_baby_seats: 'Asientos de bebe',
      booking_surf_table: 'Tabla de surf',
      booking_golf_bag: 'Bolsa de golf',
      booking_bike: 'Bicicleta',
      details: 'Detalles'
    }
  },
  services_list: {
    title: 'Traslados disponibles',
    no_results: 'No se han encontrado traslados',
    sort_by_distance: 'Ordenar por distancia',
    sort_by_departure_time: 'Ordenar por hora de salida',
    no_services_found: 'No se han encontrado traslados',
    loading: 'Cargando...'
  },
  cookie_disclaimer: {
    title: 'Su privacidad es importante para nosotros',
    description: 'Utilizamos cookies para mejorar tu experiencia de uso de esta web. Al continuar navegando, aceptas el uso de cookies. Puedes cambiar la configuración de cookies en cualquier momento.',
    configure: 'Configuración de Cookies',
    necessary: 'Necesarias',
    necessary_description: 'Obligatorias para el funcionamiento básico del sitio web. Algunos ejemplos incluyen: cookies de sesión necesarias para transmitir el sitio web, cookies de autenticación y cookies de seguridad.',
    preferences: 'Preferencias',
    preferences_description: 'Permiten recordar información que cambia el aspecto o el comportamiento del sitio web, como tu idioma preferido o la región en la que te encuentras.',
    personalize: 'Personalizar Cookies',
    continue: 'Continuar',
    accept_all: 'Aceptar todas'
  },
  legal: {
    title: 'Legal',
    general_info: 'Información general',
    terms_and_conditions: 'Condiciones generales de contratación on-line y de la reserva',
    privacy_policy: 'Política de privacidad',
    cookies_policy: 'Política de cookies',
    complaints_and_claims: 'Quejas y reclamaciones'
  },
  aria_label: {
    main_menu: {
      home: 'Ir a la página de inicio',
      search_booking: 'Buscar reserva'
    },
    header: 'Cabecera de la página',
    view_location: 'Enlace para ver la ubicación en Google Maps',
    loading: 'Cargando...',
    change_language_to_spanish: 'Cambiar idioma a español',
    change_language_to_english: 'Cambiar idioma a inglés',
    next_image: 'Siguiente imagen',
    previous_image: 'Imagen anterior',
    expand_filters: 'Expandir/Contraer filtros de búsqueda',
    expand_extra_options: 'Expandir/Contraer opciones extra',
    expand_agency_details: 'Expandir/Contraer detalles de la agencia',
    sort_options: 'Opciones de ordenación',
    sort_by_distance: 'Ordenar por distancia',
    sort_by_departure_time: 'Ordenar por hora de salida',
    decrease_quantity: 'Disminuir cantidad',
    increase_quantity: 'Aumentar cantidad',
    increase_radius: 'Aumentar radio',
    decrease_radius: 'Disminuir radio',
    menu_button: 'Abrir/Cerrar menú de usuario',
    user_icon: 'Icono de usuario',
    logout_button: 'Botón para cerrar sesión',
    logout_icon: 'Icono de cerrar sesión',
    my_bookings_link: 'Enlace a mis reservas',
    close_button: 'Botón de cerrar',
    contact_us_modal_description: 'Modal para mostrar información de contacto para Canary Tourist Shuttle',
    cookie_disclaimer: 'Aviso de cookies',
    cookie_disclaimer_button_custom: 'Botón para aceptar cookies personalizadas',
    cookie_disclaimer_button_all: 'Botón para aceptar todas las cookies',
    cookie_disclaimer_personalize: 'Botón para personalizar cookies',
    booking_id_helper_text: 'Texto de ayuda para mostrar el número de reserva'
  }
}
