import { useEffect, useState } from 'react'
import i18n from '../../translations/i18n'
import './LanguageSelector.css'
import { useNavigate } from 'react-router-dom'

const Languages = {
  English: 'en',
  Spanish: 'es'
} as const
type LanguagesType = (typeof Languages)[keyof typeof Languages]

/**
 * @returns {JSX.Element} A component that allows the user to change the language of the page.
 */
export const LanguageSelector = () => {
  const [language, setLanguage] = useState<LanguagesType>(
    i18n.language === Languages.English ? Languages.English : Languages.Spanish
  )
  const navigate = useNavigate()
  const trans = (key: string) => i18n.t(key)

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language)
      navigate(0)
    }
  }, [language])

  const Selector = ({ lang }: { lang: LanguagesType }) => {
    return (
      <button
        aria-label={
          lang === 'en'
            ? trans('aria_label.change_language_to_spanish')
            : trans('aria_label.change_language_to_english')
        }
        className={`${
          lang === i18n.language ? 'selected flex' : 'fixed'
        } flag-button h-12 w-12 items-center justify-center rounded-full border-4 border-primary-orange bg-white font-bold grayscale-[40%] hover:grayscale-0 group-hover:flex`}
        onClick={() => {
          setLanguage(lang)
        }}
        title={lang === Languages.English ? 'English' : 'Spanish'}>
        {lang.toUpperCase()}
      </button>
    )
  }

  return (
    <div className="group fixed bottom-2 right-2 z-50 flex flex-col items-center justify-center gap-2 rounded-xl bg-opacity-50  p-2  opacity-60 hover:opacity-100">
      {language === 'en' ? <Selector lang="es" /> : <Selector lang="en" />}
      {language === 'es' ? <Selector lang="es" /> : <Selector lang="en" />}
    </div>
  )
}
