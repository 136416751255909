import { RouteProps, Outlet } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

type ProtectedRouteProps = RouteProps & {
  redirectTo?: string
}

export const ProtectedRoute = ({ redirectTo = '/' }: ProtectedRouteProps) => {
  const { isLoggedIn, isLoginLoading } = useAuth()

  if (isLoginLoading) {
    return <div>Loading...</div>
  }

  if (!isLoggedIn) {
    window.location.href = redirectTo
    return <></>
  }

  return <Outlet />
}
