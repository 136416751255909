import { GOOGLE_API_KEY } from '../utils/constants'

/**
 * Convert geographical coordinates into a human-readable address
 */
export const getAddressFromLocation = async ({ lat, lng }: { lat: number; lng: number }) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
  )
    .then((response) => response.json())
    .then((data) => data.results[0].formatted_address as string)
}

export const getCurrentPosition = (callback: Function, err?: Function) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => callback(position),
      (err) => {
        if (err.code === 1) {
          alert('Please allow location access')
        }
      },
      {
        enableHighAccuracy: true,
        timeout: 6000,
        maximumAge: 0
      }
    )
  }
}

export const buildCoordinatesUrl = ({ lat, lng }: { lat: number; lng: number }) => {
  const url = new URL('https://www.google.com/maps/search/')

  url.searchParams.append('api', '1')
  url.searchParams.append('query', `${lng},${lat}`)

  return url.toString()
}

export const buildDirectionsUrl = ({ lat, lng }: { lat: number; lng: number }) => {
  const url = new URL('https://www.google.com/maps/dir/')

  url.searchParams.append('api', '1')
  url.searchParams.append('origin', 'My+Location')
  url.searchParams.append('destination', `${lat},${lng}`)

  return url.toString()
}

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

export const calculateDistance = ({ lat1, lon1, lat2, lon2 }: { lat1: number; lon1: number; lat2: number; lon2: number }) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return Math.round((d + Number.EPSILON) * 100) / 100
}
