import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ServiceSlot } from '../../interfaces/interfaces'

const initialState: ServiceSlot = {
  AvailablePax: 0,
  EndTime: '',
  EndPlaceName: '',
  Island: '',
  PlaceLatitude: 0,
  PlaceLongitude: 0,
  PlaceName: '',
  PlaceId: 0,
  PriceAdult: 0,
  PriceBabySeat: 0,
  PriceBike: 0,
  PriceChild: 0,
  PriceGolf: 0,
  PriceSurf: 0,
  PriceSpecial: 0,
  ServiceId: 0,
  StartTime: ''
}

export const serviceSlotSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setServiceSlot: (state, action: PayloadAction<ServiceSlot>) => {
      state.AvailablePax = action.payload.AvailablePax
      state.EndTime = action.payload.EndTime
      state.EndPlaceName = action.payload.EndPlaceName
      state.Island = action.payload.Island
      state.PlaceLatitude = action.payload.PlaceLatitude
      state.PlaceLongitude = action.payload.PlaceLongitude
      state.PlaceName = action.payload.PlaceName
      state.PlaceId = action.payload.PlaceId
      state.PriceAdult = action.payload.PriceAdult
      state.PriceBabySeat = action.payload.PriceBabySeat
      state.PriceBike = action.payload.PriceBike
      state.PriceChild = action.payload.PriceChild
      state.PriceGolf = action.payload.PriceGolf
      state.PriceSurf = action.payload.PriceSurf
      state.PriceSpecial = action.payload.PriceSpecial
      state.ServiceId = action.payload.ServiceId
      state.StartTime = action.payload.StartTime
    },
    clearServiceSlot: (state) => {
      state.AvailablePax = 0
      state.EndTime = ''
      state.EndPlaceName = ''
      state.Island = ''
      state.PlaceLatitude = 0
      state.PlaceLongitude = 0
      state.PlaceName = ''
      state.PlaceId = 0
      state.PriceAdult = 0
      state.PriceBabySeat = 0
      state.PriceBike = 0
      state.PriceChild = 0
      state.PriceGolf = 0
      state.PriceSurf = 0
      state.PriceSpecial = 0
      state.ServiceId = 0
      state.StartTime = ''
    }
  }
})

export const { setServiceSlot, clearServiceSlot } = serviceSlotSlice.actions

export default serviceSlotSlice.reducer
