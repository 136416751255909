import { useEffect, useState } from 'react'
import { useLocalStorage } from './useLocalStorage'
import { useQuery } from '@tanstack/react-query'
import { URL_LOGIN_AGENCIES, URL_CHECK_AUTH } from '../utils/constants'
import { useNavigate } from 'react-router-dom'

type LoginResponseData = {
  Token: string
  Name: string
  Emails: string[]
}
export type UserData = Omit<LoginResponseData, 'Token'>

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage<boolean>('isLoggedIn', false)
  const [authToken, setAuthToken] = useLocalStorage<string | null>('authToken', null)
  const [currentUserData, setCurrentUserData] = useLocalStorage<UserData | null>('currentUserData', null)
  const [isLoginError, setIsLoginError] = useState(false)
  const [isLoginLoading, setIsLoginLoading] = useState(false)

  const navigate = useNavigate()

  const clearCredentials = () => {
    setAuthToken(null)
    setCurrentUserData(null)
    setIsLoggedIn(false)
  }

  const { refetch } = useQuery(
    ['checkAuth'],
    async () => {
      const response = await fetch(`${URL_CHECK_AUTH}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true'
        },
        credentials: 'include',
        mode: 'cors'
      })

      if (!response.ok) {
        clearCredentials()
        return null
      }

      const data = await response.json()

      if (!data) {
        clearCredentials()
      } else {
        setIsLoggedIn(true)
        // todo: set current user data
      }

      return data
    },
    { enabled: false, retry: true }
  )

  const login = async (username: string, passcode: string) => {
    setIsLoginLoading(true)
    const response = await fetch(`${URL_LOGIN_AGENCIES}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify({ Username: username, Passcode: passcode })
    })
    setIsLoginLoading(false)

    if (!response.ok) {
      setIsLoginError(true)
      return
    }

    const data = (await response.json()) as LoginResponseData
    const { Token, Name, Emails } = data

    setAuthToken(Token)
    setCurrentUserData({ Name, Emails })
    setIsLoggedIn(true)
  }

  const logout = () => {
    clearCredentials()
    navigate('/agencies')
  }

  useEffect(() => {
    if (isLoggedIn) {
      refetch()
    }
  }, [isLoggedIn, refetch])

  return { isLoggedIn, isLoginLoading, isLoginError, login, logout, authToken, currentUserData }
}
