/**
 * API Urls
 */
export const URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT || ''
export const URL_GET_AVAILABLE_SERVICES = URL_ENDPOINT + process.env.REACT_APP_URL_GET_AVAILABLE_SERVICES
export const URL_SAVE_BOOKING = URL_ENDPOINT + process.env.REACT_APP_URL_SAVE_BOOKING
export const URL_GET_BOOKING = URL_ENDPOINT + process.env.REACT_APP_URL_GET_BOOKING
export const URL_LOGIN_AGENCIES = URL_ENDPOINT + process.env.REACT_APP_URL_LOGIN_AGENCIES
export const URL_CHECK_AUTH = URL_ENDPOINT + process.env.REACT_APP_URL_CHECK_AUTH
export const URL_GET_BOOKINGS = URL_ENDPOINT + process.env.REACT_APP_URL_GET_BOOKINGS

/**
 * Google Maps
 */
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || ''

/**
 * CECA Urls
 */
export const CECA_TPV_URL = process.env.REACT_APP_CECA_TPV_URL || ''

/**
 * Radius for search
 */
export const RADIUS_MIN = 0
export const RADIUS_MAX = 50
export const RADIUS_STEP = 5

/**
 * Until days
 * You can make bookings until 3 days (72 hours) from now
 */
export const UNTIL_DAYS = 3

/**
 * TAX IGIC REDUCED
 */
export const TAX_IGIC_REDUCED_RATE = 0.03
