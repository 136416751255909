import '../App.css'
import { useState, Fragment } from 'react'
import { FormHelperText, TextField } from '@mui/material'
import t, { trans } from '../translations/translate'
import { Footer } from '../components/Common/Footer'
import { useNavigate } from 'react-router-dom'
import { DatePicker } from '@mui/x-date-pickers'

const SearchBooking = () => {
  const [bookingId, setBookingId] = useState('')
  const [bookingDate, setBookingDate] = useState<string | null>(
    new Date().toISOString().split('T')[0] + 'T00:00:00.000Z'
  )
  const [bookingDateError, setBookingDateError] = useState(false)
  const [bookingDateErrorMessage, setBookingDateErrorMessage] = useState('')

  const navigate = useNavigate()

  return (
    <Fragment>
      <main className="mt-4 flex w-full flex-col items-center justify-center">
        <h1>{t('search_booking.title')}</h1>
        <form className="flex w-full max-w-xl flex-col justify-center px-4">
          <label className="p-2 text-lg font-bold" htmlFor="booking-id">
            {t('search_booking.booking_id')}
          </label>
          <TextField
            id="booking-id"
            aria-describedby={trans('aria_label.booking_id_helper_text')}
            onChange={(e) => setBookingId(e.target.value)}
            variant="outlined"
            placeholder={trans('search_booking.booking_id_placeholder')}
          />
          <FormHelperText className="p-2" id="booking-id-helper-text">
            {t('search_booking.booking_id_helper_text')}
          </FormHelperText>
          <label className="p-2 text-lg font-bold" htmlFor="booking-date">
            {t('search_booking.booking_date')}
          </label>
          <DatePicker
            renderInput={(params) => <TextField {...params} id="booking-date" />}
            inputFormat="dd/MM/yyyy"
            value={bookingDate}
            onChange={(newValue) => setBookingDate(newValue)}
          />
          {bookingDateError && <FormHelperText error>{bookingDateErrorMessage}</FormHelperText>}
          <button
            onClick={(e) => {
              e.preventDefault()
              if (bookingId.length <= 0 || bookingDate === null) {
                return
              }
              try {
                const date = new Date(bookingDate.toString()).toISOString().split('T')[0]
                navigate(`/my-booking?id=${bookingId}/${date}`)
              } catch (error) {
                if (error instanceof RangeError) {
                  setBookingDateError(true)
                  setBookingDateErrorMessage(trans('search_booking.booking_date_error'))
                } else {
                  console.error(error)
                }
              }
            }}
            disabled={bookingId.length === 0 || bookingDate === null}
            className="mt-8 flex w-full items-center justify-center rounded-md bg-primary-orange px-4 py-2 text-2xl font-bold text-white disabled:opacity-50">
            {t('search')}
          </button>
        </form>
      </main>
      <Footer />
    </Fragment>
  )
}

export default SearchBooking
