import './App.css'
import './translations/i18n'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Suspense, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Header from './components/Common/Header/Header'
import Home from './pages/Home'
import SearchBooking from './pages/SearchBooking'
import { AuthProvider } from './contexts/AuthContext'
import { ProtectedRoute } from './components/Common/ProtectedRoute'
import { CookieDisclaimer } from './components/CookieDisclaimer'

const BookingForm = lazy(() => import('./pages/BookingForm'))
const Services = lazy(() => import('./pages/Services'))
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'))
const PaymentError = lazy(() => import('./pages/PaymentError'))
const Login = lazy(() => import('./pages/agencies/Login'))
const Bookings = lazy(() => import('./pages/agencies/MyBookings'))

const client = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={client}>
      <AuthProvider>
        <div className="App">
          <Header />
          <Routes>
            <Route index path="/" element={<Home />} />

            <Route path="search-booking" element={<SearchBooking />} />
            <Route path="my-booking" element={<Suspense><PaymentSuccess /></Suspense>}/>

            <Route path="booking">
              <Route path="select-transfer" element={<Suspense><Services /></Suspense>}/>
              <Route path="form" element={<Suspense><BookingForm /></Suspense>}/>
              <Route path="success" element={<Suspense><PaymentSuccess /></Suspense>}/>
              <Route path="payment-error" element={<Suspense><PaymentError /></Suspense>}/>
              <Route index element={<Navigate to="/" />} />
            </Route>

            <Route path="agencies">
              <Route element={<ProtectedRoute redirectTo='/agencies/login' />}>
                <Route path="my-bookings" element={<Suspense><Bookings /></Suspense>}/>
              </Route>
              <Route path="login" element={<Suspense><Login /></Suspense>}/>
              <Route index element={<Navigate to="/agencies/login" />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <CookieDisclaimer />
        </div>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
